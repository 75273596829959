<template>
  <v-container>
    <div class="my-9">
      <div class="text-h3 mb-6" data-aos="fade-in" >Newsroom</div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped></style>
